import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import apiUrl from "../config/config";

import { GET_ERRORS, SET_CURRENT_USER, UPDATE_PROFILE_IMAGE } from "./types";

//Register User
export const registerUser = (userData, history, redirectUrl) => (dispatch) => {
  axios
    .post(apiUrl + "api/users/register", userData)
    .then((res) => {
      history.push(redirectUrl);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Register User
export const updateUser = (userData, history, redirectUrl) => (dispatch) => {
  axios
    .post(apiUrl + "api/users/updateuser", userData)
    .then((res) => {
      history.push(redirectUrl);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Register User
export const updateProfile = (userData, history) => (dispatch) => {
  axios
    .post(apiUrl + "api/users/updateprofile", userData)
    .then((res) => {
      window.location.reload();
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Register User
export const updatePatinetProfile = (userData, history) => (dispatch) => {
  axios
    .post(apiUrl + "api/patients/updateprofile", userData)
    .then((res) => {
      window.location.reload();
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Register User
export const updateDoctor = (userData, history, redirectUrl) => (dispatch) => {
  axios
    .post(apiUrl + "api/users/updatedoctor", userData)
    .then((res) => {
      window.location.reload();
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(apiUrl + "api/users/login", userData)
    .then((res) => {
      //Save to localstorage
      const { token } = res.data;
      // console.log('userid!!!!!!!!!!!!!!!!!:',res.data.payload.id)
      //Set token to ls
      localStorage.setItem("jwtToken", token);

      localStorage.setItem("verifiertype", res.data.payload.type);
      localStorage.setItem("verifierid", res.data.payload.id);
      localStorage.setItem("verifiername", res.data.payload.name);
      sessionStorage.setItem("jwtToken", token);
      sessionStorage.setItem("verifierid", res.data.payload.type);
      sessionStorage.setItem("jwtToken", res.data.payload.id);
      sessionStorage.setItem("jwtToken", res.data.payload.name);

      //Set token to Auth header
      setAuthToken(token);
      // Decode token to get userdata
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      console.log(res.data);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//Login - Get User Token
export const profileImageUpdate = (Data) => (dispatch) => {
  axios
    .post(apiUrl + "api/users/imgUpload", Data)
    .then((res) => {
      // then print response status
      //console.log(this.props.auth.user);
      console.log(res.data.profileImage);
      dispatch({
        type: UPDATE_PROFILE_IMAGE,
        payload: res.data.profileImage,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

//Register Patient
export const registerPatient =
  (userData, history, redirectUrl) => (dispatch) => {
    axios
      .post(apiUrl + "api/patients/register", userData)
      .then((res) => {
        //console.log(res);
        history.push(redirectUrl);
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      );
  };

//Login - Get User Token
export const loginPatient = (userData) => (dispatch) => {
  axios
    .post(apiUrl + "api/patients/login", userData)
    .then((res) => {
      //Save to localstorage
      const { token } = res.data;
      //Set token to ls
      localStorage.setItem("jwtToken", token);
      //Set token to Auth header
      setAuthToken(token);
      // Decode token to get userdata
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log User out
export const logoutUser = () => (dispatch) => {
  //Remove token from localStorage
  localStorage.removeItem("jwtToken");

  localStorage.removeItem("verifiertype");
  localStorage.removeItem("verifierid");
  localStorage.removeItem("verifiername");
  sessionStorage.removeItem("jwtToken");
  sessionStorage.removeItem("verifiertype");
  sessionStorage.removeItem("verifierid");
  sessionStorage.removeItem("verifiername");

  // Remove auth header for future requests
  setAuthToken(false);
  //Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
