import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../../config/config";

class SideBarGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      test: "test",
      patientId: "",
    };
  }

  componentDidMount() {
    axios
      .get(apiUrl + "api/patients/patientUniquId?userid=" + this.props.userid)
      .then((res) => {
        this.setState({
          patientId: res.data.device_id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onClick = (e) => {
    e.preventDefault();
  };

  render() {
    const userid = this.props.userid;
    var databaseid = this.props.databaseid;
    var cdate = databaseid.split(" ");

    return (
      <div className="fixed-sidebar-left">
        <ul className="nav navbar-nav side-nav nicescroll-bar">
          {this.props.menus.map(function (menu, i) {
            return (
              <li key={i}>
                <a
                  href={menu.url}
                  data-toggle="collapse"
                  data-target="#dashboard_dr"
                >
                  <div className="pull-left">
                    <i className="zmdi zmdi-window-maximize mr-20"></i>
                    <span className="right-nav-text">{menu.title}</span>
                  </div>
                  <div className="pull-right"></div>
                  <div className="clearfix"></div>
                </a>
              </li>
            );
          })}
          <li>
            <a href="#" data-toggle="collapse" data-target="#app_dr">
              <div className="pull-left">
                <i className="zmdi zmdi-account-add mr-20"></i>
                <span className="right-nav-text">{this.state.patientId}</span>
              </div>
              <div className="pull-right">
                <i className="zmdi zmdi-caret-down"></i>
              </div>
              <div className="clearfix"></div>
            </a>
            <ul id="app_dr" className="collapse collapse-level-1">
              {Object.keys(this.props.treemenu).map((val, key) => {
                var mainActClass = "";
                if (cdate[0] == val) {
                  mainActClass = "treeActive";
                }
                if (databaseid == "0" && key == 0) {
                  mainActClass = "treeActive";
                }
                return (
                  <li key={key}>
                    <a
                      href="#"
                      className={mainActClass}
                      data-toggle="collapse"
                      data-target={"#" + key}
                    >
                      {val}
                      <div className="pull-right">
                        <i className="zmdi zmdi-caret-down text-green"></i>
                      </div>
                      <div className="clearfix"></div>
                    </a>
                    <ul id={key} className="collapse collapse-level-2">
                      {this.props.treemenu[val].map((value, key) => {
                        var time = value.split(" ");
                        var actClass = "";
                        if (value == databaseid) {
                          actClass = "treeActive";
                        }
                        if (databaseid == "0" && key == 0) {
                          actClass = "treeActive";
                        }
                        return (
                          <li key={key} className={actClass}>
                            <a href={"/user-chart/" + value}>
                              <div className="pull-left">
                                <i className="zmdi zmdi-file mr-20 text-green"></i>
                                <span className="right-nav-text text-green">
                                  {time[1]}
                                </span>
                              </div>
                              <div className="clearfix"></div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideBarGraph;
