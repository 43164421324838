import React, { Component } from "react";
import axios from "axios";
import apiUrl from "../../config/config";
import TopNav from "../common/TopNav";
import Select from "react-select";
import $ from "jquery";

import "./distributor.css";

class Distributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], // Stores the table data
      profileLink: "verifier-profile",
      searchTerm: "", // Holds the current search term
      currentPage: 1, // Current page for pagination
      itemsPerPage: 100, // Number of items per page
      maxPageButtons: 8, // Maximum number of page buttons to show
      sortColumn: "", // Column to sort by
      sortDirection: "asc", // Sort direction: 'asc' or 'desc'
      userid: "",
      patient_name: "",
      email_id: "",
      hospital: "",
      selectedDoctor: null,
      duration: "",
      dob: "",
      Phonenumber: "",
      ipop: "",
      gender: "",
      doctors: [],
      errors: {},
      selectedDate: "",
    };
    this.getInfo = this.getInfo.bind(this);
  }

  componentDidMount() {
    this.fetchData(); // Initial call
    this.interval = setInterval(() => {
      this.fetchData();
    }, 30000); // 30000ms = 30 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Clear the interval to prevent memory leaks
  }

  fetchData = async () => {
    try {
      const verifiertype = localStorage.getItem("verifiertype");
      const customerid = localStorage.getItem("verifierid");

      const response = await axios.get(
        `${apiUrl}api/users/getVerifierPatientsPercentage_testnew`,
        {
          params: { userid: customerid },
        }
      );

      if (response.data && response.data.patients) {
        const sortedPatients = response.data.patients.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });
        const alldoctors = await axios.get(`${apiUrl}api/users/alldoctors`);
        console.log(alldoctors.data);
        this.setState({ doctors: alldoctors.data });

        this.setState({
          data: sortedPatients.map((patient, index) => {
            // Calculate time difference

            const dateSub = patient.subscribeLogs[0].date_sub; // Example date_sub value
            const adjustedDate = new Date(dateSub.split(".")[0]); // Parse the date part
            adjustedDate.setHours(adjustedDate.getHours() + 5); // Add 5 hours
            adjustedDate.setMinutes(adjustedDate.getMinutes() + 30); // Add 30 minutes

            const now = new Date(); // Get the current time
            const timeDifference = now - adjustedDate; // Difference in milliseconds

            // Convert the difference to hours
            const diffInSeconds = Math.floor(timeDifference / 1000); // seconds
            const diffInMinutes = Math.floor(diffInSeconds / 60); // minutes
            const diffInHours = Math.floor(diffInMinutes / 60); // hours
            const diffInDays = Math.floor(diffInHours / 24); // days

            // console.log(
            //   `Current Time: ${now.toLocaleString().replace(",", "")}`
            // );
            // console.log(
            //   `Adjusted Time: ${adjustedDate.toLocaleString().replace(",", "")}`
            // );
            // console.log(
            //   `Time Difference: ${diffInDays} days, ${
            //     diffInHours % 24
            //   } hours, ${diffInMinutes % 60} minutes`
            // );

            // Monitoring duration logic
            const monitoringDuration = patient.exp_device_use_days; // Example monitoringDuration (1 = 24hrs, 2 = 48hrs, 3 = 72hrs)
            const maxDurationInHours = monitoringDuration * 24; // Convert to hours (1 -> 24hrs, 2 -> 48hrs, etc.)

            // Declare the variable outside of the if statement
            let percentageUsed = 0;

            // Calculate the percentage only if the time difference is within the allowed limit
            if (diffInHours <= maxDurationInHours) {
              // Calculate the percentage of time used towards the limit
              percentageUsed = (diffInHours / maxDurationInHours) * 100;
              // console.log(
              //   `Percentage of time used: ${percentageUsed.toFixed(2)}%`
              // );
            } else {
              // console.log(
              //   `Time difference exceeds the allowed limit of ${maxDurationInHours} hours.`
              // );
            }

            // Now you can use the percentageUsed variable here outside the if statement
            // console.log(
            //   `Percentage Used (Outside): ${percentageUsed.toFixed(2)}%`
            // );

            return {
              slno: index + 1,
              patientId: patient.device_id || "N/A",
              name: patient.name || "N/A",
              createdDate: patient.created_at
                ? patient.created_at.split("T")[0]
                : "N/A",
              duration: `${patient.exp_device_use_days} (${adjustedDate
                .toLocaleString()
                .replace(",", "")})`, // Update duration
              percentage: `${percentageUsed || "100"}`, // Add the percentage of elapsed monitoring time
              phone: patient.phone || "N/A",
              userid: patient._id,
              mailId: patient.email || "N/A",
              gender: patient.gender || "N/A",
              battery: `${patient.BatteryPercentage}%` || "N/A",
              recordingStatus: (() => {
                if (patient.updatedDate) {
                  return "Data Upload"; // If `updatedDate` exists, set status to "Data Upload"
                }
                switch (patient.RecordingStatus) {
                  case 0:
                    return "Not Recording";
                  case 1:
                    return "In Recording";
                  case 3:
                    return "Data Upload"; // Retaining this in case there are other conditions where recording status could be "Data Upload"
                  default:
                    return "N/A"; // Fallback for unexpected values
                }
              })(),
              uploadedTime: patient.updatedDate || "_",
              doctorName: patient.doctorName || "N/A",
              hospitalName: patient.hospital || "N/A",
              fileCount: patient.data_count || "N/A",
              devicenumber: patient.deviceInfo.serial_no || "N/A",
              device_Sub_time: patient.subscribeLogs[0].date_sub || "N/A",
              updatedDate: patient.updatedDate,
            };
          }),
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value, currentPage: 1 });
  };
  onSubmitInfo = (e) => {
    e.preventDefault();

    //   this.state.selectedVerifiers.forEach(verifier => {
    //     console.log("Verifier ID:", verifier.value);
    // });

    const info = {
      name: this.state.patient_name,
      userid: this.state.userid,
      // dob: this.state.dob,
      hospital: this.state.hospital,
      phone: this.state.Phonenumber,
      email: this.state.email_id,
      doctorid: this.state.selectedDoctor.value,
      ipop: this.state.ipop,
      exp_device_use_days: this.state.duration,
      gender: this.state.gender,
    };
    // console.log('info:',info);
    // console.log("info date!!!!!!!!!",this.state);

    axios
      .post(apiUrl + "api/patients/updateInfo_distributor", info)
      .then((res) => {
        console.log("updated!!!!!!!!", res);
      })
      .then((res) => {
        // Handle the response of the second request
        // console.log("Device priority updated:", res.data);
        $(".closePopup").click();
        this.fetchData(); // Fetch updated data
      })
      .catch((err) => {
        // Handle any errors from either request
        console.error("Error occurred:", err);
      });
  };

  handleExportToCSV = () => {
    const { searchTerm, sortColumn, sortDirection } = this.state;

    // Get filtered and sorted data
    let filteredData = this.getFilteredData();
    if (sortColumn) {
      filteredData = [...filteredData].sort((a, b) => {
        if (sortColumn === "createdDate") {
          const dateA = new Date(a[sortColumn]);
          const dateB = new Date(b[sortColumn]);
          return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
        }
        const valueA = String(a[sortColumn]).toLowerCase();
        const valueB = String(b[sortColumn]).toLowerCase();
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });
    }

    // Convert data to CSV format
    const csvRows = [];
    const headers = [
      "SL NO",
      "PATIENT ID",
      "NAME",
      "CREATED DATE",
      "DURATION",
      "PHONE",
      "MAIL ID",
      "GENDER",
      "BATTERY",
      "RECORDING STATUS",
      "UPLOADED TIME",
      "DOCTOR NAME",
      "HOSPITAL NAME",
      "SERIAL_NO",
      "FILE COUNT",
    ];
    csvRows.push(headers.join(",")); // Add headers

    filteredData.forEach((row) => {
      const values = [
        row.slno,
        row.patientId,
        row.name,
        row.createdDate,
        row.duration,
        row.phone,
        row.mailId,
        row.gender,
        row.battery,
        row.recordingStatus,
        row.uploadedTime,
        row.doctorName,
        row.hospitalName,
        row.devicenumber,
        row.fileCount,
      ];
      csvRows.push(values.map((value) => `"${value}"`).join(",")); // Add row values
    });

    // Create a Blob and trigger download
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Filtered_Distributor_Data.csv`;
    link.click();
    URL.revokeObjectURL(url);
  };

  // Filter data based on the search term
  getFilteredData = () => {
    const { data, searchTerm, startDate, endDate } = this.state;

    return data.filter((patient) => {
      // Convert patient date to match input format
      const patientDate = patient.createdDate
        ? patient.createdDate.split("T")[0]
        : "";

      // Convert input dates to Date objects for comparison
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;
      const current = new Date(patientDate);

      // Check if search term matches any field
      const matchesSearch =
        !searchTerm ||
        Object.values(patient).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );

      // Check if patient date falls within the selected range
      const matchesDate =
        (!start || current >= start) && (!end || current <= end);

      return matchesSearch && matchesDate;
    });
  };

  // Sort data by selected column
  handleSort = (column) => {
    const { data, sortColumn, sortDirection } = this.state;
    let newDirection = "asc";

    if (sortColumn === column && sortDirection === "asc") {
      newDirection = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      if (column === "createdDate") {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);
        return newDirection === "asc" ? dateA - dateB : dateB - dateA;
      }

      if (column === "slno" || column === "patientId" || column === "name") {
        const valueA = String(a[column]).toLowerCase();
        const valueB = String(b[column]).toLowerCase();
        return newDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0; // Fallback for other columns
    });

    this.setState({
      data: sortedData,
      sortColumn: column,
      sortDirection: newDirection,
    });
  };
  getInfo(e) {
    var userid = e.currentTarget.id;

    // console.log("current target", e.currentTarget);
    this.setState({ userid: userid });

    axios
      .get(`${apiUrl}api/patients/getPatinetAdditionalInfo?userid=${userid}`)
      .then((res) => {
        const data = res.data[0];
        if (
          data.gender === "MALE" ||
          data.gender === "male" ||
          data.gender === "Male" ||
          data.gender === "M" ||
          data.gender === "m"
        ) {
          this.setState({ gender: "M" });
        }

        if (
          data.gender === "FEMALE" ||
          data.gender === "female" ||
          data.gender === "Female" ||
          data.gender === "F" ||
          data.gender === "f"
        ) {
          this.setState({ gender: "F" });
        }
        if (data.gender === "Select Gender") {
          this.setState({ gender: "M" });
        }

        if (Array.isArray(this.state.patients)) {
          this.state.patients.forEach((patient) => {
            if (patient._id === userid) {
              this.setState({ reportstatus: patient.report_status });
              // console.log("report status", patient.report_status);
            }
          });
        }
        data.customer_details.forEach((customer) => {
          if (customer.customer_details) {
            // console.log('Nested Customer Details:', customer);
            this.setState({ customer_details: customer.customer_details });
          }
        });
        // console.log(this.state.patients);

        // Update state with patient info
        this.setState({
          duration: data.exp_device_use_days,

          hospital: data.hospital,
          phone: data.phone,
          patient_name: data.name,
          email_id: data.email,
          selectedDoctor: { value: data.doctor_id, label: data.doctor_name },
          Phonenumber: data.phone,
          ipop: data.ip_op,
          dob: data.dob,
          duration: data.exp_device_use_days,
        });

        // Handle DOB formatting
        const dob = new Date(Date.parse(data.dob));
        const formattedDob = `${
          dob.getMonth() + 1
        }/${dob.getDate()}/${dob.getFullYear()}`;
        this.setState({ dob: formattedDob });

        // Second API call for verifiers
        return axios.get(`${apiUrl}api/users/allverifiers`);
      })
      .then((res) => {
        const verifiers = res.data;
        const formattedVerifiers = verifiers.map((verifier) => ({
          value: verifier._id,
          label: verifier.name,
          patients: verifier.patients, // Assuming patients is an array of user IDs
        }));

        const filteredVerifiers = formattedVerifiers.filter((verifier) =>
          verifier.patients.includes(userid)
        );

        // console.log('Filtered verifiers with matching user:', this.state.priority);

        this.setState({ selectedVerifiers: filteredVerifiers });

        this.setState({ verifiers: formattedVerifiers });
        // console.log('formatted verifiers:',verifiers)
        // Third API call for patient search (ipop)
        this.state.patients.forEach((patient) => {
          if (patient._id === userid) {
            this.setState({ comments: patient.comments });
            // this.setState({ priority: patient.priority });
            this.setState({ ipop: patient.ip_op });
          }
        });

        // console.log("ipop", this.state.patients);

        // Fourth API call for verified percentage
        return axios.get(`${apiUrl}api/users/getVerifiedPercentage/${userid}`);
      })
      .then((res) => {
        const ecgValues = res.data.ecgValues; // Assuming `ecgValues` is an array
        const totalLength = ecgValues.length;
        let verifyCount = 0; // Initialize verify count

        ecgValues.forEach((value, index) => {
          if (value.verifyStatus === 1) {
            // console.log(`Link for ECG value at index ${index}:`, value.link); // Assuming there's a 'link' property
            verifyCount++; // Increment the verify count
          }
        });

        // console.log('Total verified ECG values:', verifyCount);
        // console.log('Total count:', totalLength);

        if (totalLength > 0) {
          // console.log('ECG data is available.');

          // Calculate and log the percentage of verified ECG values
          const percentageVerified = (verifyCount / totalLength) * 100;
          // console.log('Percentage of verified ECG values:', percentageVerified.toFixed(2) + '%');
          this.setState({ percentageVerified: percentageVerified.toFixed(2) });
          // console.log('verified percentage:',this.state.percentageVerified)
        } else {
          // console.log('No ECG data found.');
        }
      })
      .catch((error) => {
        console.error("Error fetching ECG data:", error);
      });
  }

  // Get paginated data for the current page
  getPaginatedData = () => {
    const filteredData = this.getFilteredData();
    const { currentPage, itemsPerPage } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    return filteredData.slice(indexOfFirst, indexOfLast);
  };

  // Handle page change
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  // Handle Next Page
  handleNextPage = () => {
    const { currentPage } = this.state;
    const totalPages = Math.ceil(
      this.getFilteredData().length / this.state.itemsPerPage
    );
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  // Handle Previous Page
  handlePrevPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };
  handleDoctorChange = (selectedOption) => {
    this.setState({ selectedDoctor: selectedOption });
    // console.log("Selected doctor:", selectedOption);
    // Perform any action needed with the selected doctor
  };
  handleStartDateChange = (date) => {
    this.setState({ startDate: date }, this.filterDataByDateRange);
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date }, this.filterDataByDateRange);
  };
  getMaxDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Returns today's date in YYYY-MM-DD format
  };

  getMinDate = () => {
    const today = new Date();
    today.setMonth(today.getMonth() - 3); // Go back 3 months
    return today.toISOString().split("T")[0]; // Returns min date in YYYY-MM-DD format
  };

  render() {
    const { errors, doctors, selectedDoctor } = this.state;
    const options = doctors.map((doctor) => ({
      value: doctor._id,
      label: doctor.name,
    }));

    const paginatedData = this.getPaginatedData();
    const filteredData = this.getFilteredData();
    const totalPages = Math.ceil(filteredData.length / this.state.itemsPerPage);
    const { currentPage, sortColumn, sortDirection, maxPageButtons } =
      this.state;

    // Create page buttons
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(i);
    }

    // Calculate visible page buttons (only show `maxPageButtons` at a time)
    const startIndex = Math.max(
      0,
      currentPage - Math.floor(maxPageButtons / 2) - 1
    );
    const endIndex = Math.min(pageButtons.length, startIndex + maxPageButtons);
    const visiblePageButtons = pageButtons.slice(startIndex, endIndex);

    return (
      <div style={{ backgroundColor: "#24333e" }}>
        <TopNav profileLink={this.state.profileLink} />
        <div id="editInfoModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  // onClick={this.resetInfoModal.bind(this)}
                >
                  &times;
                </button>
                <h4 className="modal-title text-center">
                  Edit Patient Information
                </h4>
              </div>
              <form method="post" onSubmit={this.onSubmitInfo}>
                <div className="modal-body">
                  <div className="row row-sm-offset">
                    <br />
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="patient_name"
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState({ patient_name: e.target.value });
                          }}
                          value={this.state.patient_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email_id"
                          onChange={(e) => {
                            this.setState({ email_id: e.target.value });
                          }}
                          value={this.state.email_id}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm-offset">
                    <br />
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          Doctor Name
                        </label>

                        <Select
                          options={options} // Provide the options
                          value={selectedDoctor} // Controlled component value
                          onChange={this.handleDoctorChange} // Handle selection
                          placeholder="Search Doctor"
                          classNamePrefix="react-select"
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: errors.doctor_id
                                ? "red"
                                : base.borderColor,
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          PHONE NUMBER
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="Phone-number"
                          onChange={(e) => {
                            this.setState({ Phonenumber: e.target.value });
                          }}
                          value={this.state.Phonenumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm-offset">
                    <br />
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          Monitoring Duration
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="MonitoringDuration"
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState({ duration: e.target.value });
                          }}
                          value={this.state.duration}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          Gender
                        </label>
                        <select
                          name="Gender"
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ gender: e.target.value });
                          }}
                          value={this.state.gender}
                        >
                          <option value="M">M</option>

                          <option value="F">F</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row row-sm-offset">
                    <br />
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          Hospital Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="HospitalName"
                          onChange={(e) => {
                            console.log(e.target.value);
                            this.setState({ hospital: e.target.value });
                          }}
                          value={this.state.hospital}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 multi-horizontal">
                      <div className="form-group">
                        <label
                          className="form-control-label mbr-fonts-style display-7"
                          style={{ color: "Black" }}
                        >
                          IP/OP
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="Gender"
                          onChange={(e) => {
                            this.setState({ ipop: e.target.value });
                          }}
                          value={this.state.ipop}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row row-sm-offset">
                    <div className="col-md-6 multi-horizontal">
                      <button
                        type="submit"
                        className="btn btn-success"
                        style={{ color: "Black" }}
                      >
                        Submit
                      </button>
                    </div>
                    {
                      <div className="col-md-6 multi-horizontal text-left">
                        <button
                          id=""
                          type="button"
                          className="btn btn-default closePopup"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <h2>Table of Distributor Data</h2>

        {/* Search Input */}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            marginTop: "70px",
          }}
        >
          <input
            type="text"
            placeholder="Search patients..."
            value={this.state.searchTerm}
            onChange={this.handleSearchChange}
            style={{
              padding: "10px",
              width: "300px",
              borderRadius: "5px",
              border: "1px solid #ddd",
              fontSize: "16px",
              color: "black",
            }}
          />

          {/* Start Date Picker with Restriction */}
          <input
            type="date"
            value={this.state.startDate}
            onChange={(e) => this.handleStartDateChange(e.target.value)}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "Start Date")}
            placeholder="Start Date"
            min={this.getMinDate()} // 3 months before today
            max={this.getMaxDate()} // Today
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ddd",
              fontSize: "16px",
              color: "black",
              cursor: "pointer",
              minWidth: "130px",
            }}
          />

          {/* End Date Picker with Restriction */}
          <input
            type="date"
            value={this.state.endDate}
            onChange={(e) => this.handleEndDateChange(e.target.value)}
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "End Date")}
            placeholder="End Date"
            min={this.getMinDate()} // 3 months before today
            max={this.getMaxDate()} // Today
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ddd",
              fontSize: "16px",
              color: "black",
              cursor: "pointer",
              minWidth: "130px",
            }}
          />

          <button
            onClick={this.handleExportToCSV}
            style={{
              padding: "8px 12px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Export to CSV
          </button>
        </div>

        {/* Scrollable Table Wrapper */}
        <div style={tableWrapperStyle}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "0%",
            }}
          >
            <thead>
              <tr>
                <th style={headerStyle} onClick={() => this.handleSort("slno")}>
                  SL NO
                </th>
                <th
                  style={headerStyle}
                  onClick={() => this.handleSort("patientId")}
                >
                  PATIENT ID
                </th>
                <th
                  style={{ ...headerStyle, width: "132px" }}
                  onClick={() => this.handleSort("name")}
                >
                  NAME
                </th>
                <th
                  style={headerStyle}
                  onClick={() => this.handleSort("createdDate")}
                >
                  CREATED DATE
                </th>
                <th style={headerStyle}>DURATION</th>
                <th style={headerStyle}>PHONE</th>
                <th style={headerStyle}>MAIL ID</th>
                <th style={headerStyle}>GENDER</th>
                <th style={headerStyle}>BATTERY</th>
                <th style={headerStyle}>RECORDING STATUS</th>
                <th style={headerStyle}>UPLOADED TIME</th>
                <th style={{ ...headerStyle, width: "132px" }}>DOCTOR NAME</th>
                <th style={{ ...headerStyle, width: "300px" }}>
                  HOSPITAL NAME
                </th>
                <th style={headerStyle}>SERIAL_NO</th>
                <th style={headerStyle}>FILE COUNT</th>
                <th style={headerStyle}>EDIT</th>

                {/* Added percentage column */}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((patient, index) => {
                // Determine row color based on the presence of updatedDate
                const rowBackgroundColor = patient.updatedDate
                  ? "#086910"
                  : "#5e0b04";

                // Calculate the percentage (from the previous calculation)
                // console.log("updated data!!!!!!!!!!!!!!!!", patient);
                const globalIndex =
                  (this.state.currentPage - 1) * this.state.itemsPerPage +
                  (index + 1);

                return (
                  <React.Fragment key={patient.slno}>
                    {/* First row with patient data */}
                    <tr
                      style={{
                        ...rowStyle,
                        backgroundColor: rowBackgroundColor,
                      }}
                    >
                      <td style={cellStyle}>{globalIndex}</td>
                      <td style={cellStyle}>{patient.patientId}</td>
                      <td style={{ ...cellStyle, textAlign: "left" }}>
                        {patient.name}
                      </td>
                      <td style={cellStyle}>{patient.createdDate}</td>
                      <td style={cellStyle}>{patient.duration}</td>
                      <td style={cellStyle}>{patient.phone}</td>
                      <td style={cellStyle}>{patient.email}</td>
                      <td style={cellStyle}>{patient.gender}</td>
                      <td style={cellStyle}>{patient.battery}</td>
                      <td style={cellStyle}>{patient.recordingStatus}</td>
                      <td style={cellStyle}>{patient.uploadedTime}</td>
                      <td style={{ ...cellStyle, textAlign: "left" }}>
                        {patient.doctorName}
                      </td>
                      <td
                        style={{
                          ...cellStyle,
                          textAlign: "left",
                          paddingLeft: "100px",
                        }}
                      >
                        {patient.hospitalName}
                      </td>
                      <td style={cellStyle}>{patient.devicenumber}</td>
                      <td style={cellStyle}>{patient.fileCount}</td>
                      <td style={cellStyle}>
                        <button
                          id={patient.userid}
                          data-toggle="modal"
                          data-target="#editInfoModal"
                          style={{
                            backgroundColor: "#F0C541",
                            border: "none",
                            width: "80px",
                            height: "40px",
                            fontSize: "15px",
                          }}
                          onClick={this.getInfo}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>

                    {/* Second row for the progress bar */}
                    {patient.recordingStatus !== "Data Upload" && (
                      <tr style={{ backgroundColor: "none"   }}>
                        <td colSpan="15" style={{ padding: "10px" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "4px",
                              backgroundColor: "#ddd",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                width: `${patient.percentage}%`,
                                height: "100%",
                                backgroundColor:
                                  patient.percentage <= 50
                                    ? "#006D5B"
                                    : patient.percentage <= 89
                                    ? "#006D5B"
                                    : "#006D5B",
                                borderRadius: "5px",
                              }}
                            ></div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div style={paginationStyle}>
          <button
            onClick={this.handlePrevPage}
            style={pageButtonStyle}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {visiblePageButtons.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => this.handlePageChange(pageNumber)}
              style={pageButtonStyle}
              className={currentPage === pageNumber ? "active" : ""}
            >
              {pageNumber}
            </button>
          ))}

          <button
            onClick={this.handleNextPage}
            style={pageButtonStyle}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

// Inline styles for headers, cells, and pagination
const headerStyle = {
  border: "none",
  textAlign: "center",
  padding: "10px",
  backgroundColor: "#24333e",
  cursor: "pointer", // Make the headers clickable
};

const cellStyle = {
  border: "none",
  padding: "10px",
  textAlign: "center",
};

const rowStyle = {
  textAlign: "center",
  backgroundColor: "#24333e",
};

const searchStyle = {
  padding: "10px",
  marginBottom: "20px",
  width: "100%",
  maxWidth: "300px",
  display: "block",
  marginLeft: "83%",
  marginTop: "4%",
  borderRadius: "5px",
  border: "1px solid #ddd",
  fontSize: "16px",
  color: "black",
};

const paginationStyle = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",
};

const pageButtonStyle = {
  padding: "5px 10px",
  margin: "0 5px",
  border: "1px solid #ddd",
  cursor: "pointer",
  backgroundColor: "#24333e",
  color: "white",
};

// Scrollable table wrapper style
const tableWrapperStyle = {
  overflowX: "auto", // Enable horizontal scrolling
  marginTop: "20px", // Optional margin
};

export default Distributor;
