import React, { Component } from "react";
import ReactModal from "react-modal";
import apiUrl from "../../config/config";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Loader from "react-loader-spinner";
import './modals.css' 

class AddModalComplete extends Component {
  // ... skip
  constructor() {
    super();
    this.state = {
      showModalComplete: false,
      showModalNewComplete: false,
      showModalSpinnerComplete: false,
      startProp: "",
      showInputPopup:false,
      inputValue:'',
      userid: "",
      treemenu:[],
      starttime:'',
      endtime:'',
    };
    this.handleCloseModalComplete = this.handleCloseModalComplete.bind(this);
    this.handleCloseModalNewComplete =
      this.handleCloseModalNewComplete.bind(this);
    this.handleCloseModalSpinnerNewComplete =
      this.handleCloseModalSpinnerNewComplete.bind(this);
  }
  componentDidMount() {
    ReactModal.setAppElement("body");
    window.analyzeLink = "";
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);

    this.state.showModalComplete = nextProps.isOpen;
    this.state.userid = nextProps.userid;
    this.state.startProp = nextProps.start;
    this.state.treemenu=nextProps.treemenu
  }
  handleCloseModalComplete() {
    this.setState({ showModalComplete: false });
    console.log('closemodal',this.state.showModalComplete)
  }

  handleCloseModalNewComplete() {
    // console.log('handleCloseModalNewComplete');
    this.setState({ showModalNewComplete: false });
    this.setState({showModalComplete:false})
  }
  handleCloseModalSpinnerNewComplete() {
    // console.log('handleCloseModalNewComplete');
    this.setState({ showModalSpinnerComplete: false });
  }
  handleOpenModalSpinnerNew() {
    // console.log('handleCloseModalNewComplete');
    this.setState({ showModalSpinnerComplete: true });
  }
 async openAnalyzemodal() {
    const response=await axios.get(`${apiUrl}api/common/patinentmenutreeNew?userid=${this.state.userid}`)
    // console.log('treemenu:!!!!!!!!!!',response.data)
    const treemenu = response.data;
      let optionnewon = []; // For ascending order
      let optionnewendon = []; // For descending order

      // Iterate over each date group in treemenu
      Object.keys(treemenu).forEach((dateKey) => {
        treemenu[dateKey].forEach((entry) => {
          const MainTimeon = entry.date.split("T");
          const timeon = MainTimeon[1].split("+")[0]; // Extract time portion, ignoring timezone

          // Create the formatted time string: "date time"
          const timeStr = `${dateKey} ${timeon}`;

          // Push to both arrays
          optionnewon.push(timeStr);
          optionnewendon.push(timeStr);
        });
      });

      // Sort optionnewon (ascending order) by full date-time
      optionnewon.sort((a, b) => {
        const fullDateA = new Date(a); // Convert full date-time string to Date object
        const fullDateB = new Date(b); // Convert full date-time string to Date object
        return fullDateA - fullDateB; // Ascending order: compare full date-time
      });

      // Sort optionnewendon (descending order) by full date-time
      optionnewendon.sort((a, b) => {
        const fullDateA = new Date(a); // Convert full date-time string to Date object
        const fullDateB = new Date(b); // Convert full date-time string to Date object
        return fullDateB - fullDateA; // Descending order: compare full date-time
      });

      // Output the results
      // console.log('Sorted Ascending:', optionnewon);
      const formattedOptionNewEndon = optionnewendon.map((value, index) => ({
        index,
        value,
      }));

      this.setState({treemenu:formattedOptionNewEndon})
    
    this.setState({starttime:optionnewon[0]})
    this.setState({endtime:optionnewendon[0]})
    this.setState({ showModalComplete: false });
    this.setState({ showModalNewComplete: true });
  }
  handleInputChange(e) {
    this.setState({ inputValue: e.target.value });
    

    
  }
  handleInputPopupClose() {
    this.setState({ showInputPopup: false });

  }

  handleInputPopupOk() {
    // const inputvalue=this.state.inputValue
// console.log(inputvalue) 
    if (this.state.inputValue) {
      this.setState({ showInputPopup: false });
      // this.analyzeDataset.bind({ id: "temp2" });
      this.setState({})
      
      this.handleCloseModalNewComplete();

      this.handleOpenModalSpinnerNew();

      const response=axios.get(
        `https://web.mybiocalculus.com/html/welcome/amplitude_increase/?userid=${this.state.userid}&&st_time=${this.state.starttime}&&ed_time=${this.state.endtime}&&val=${this.state.inputValue}`
      ).then((res)=>{
        this.handleCloseModalSpinnerNewComplete()
      })

    }
  }

  analyzeDataset(e) {
    var userid = this.state.userid;
    var databaseid = this.state.clickdata;
   
    if (e.currentTarget.id == "ai") {
      //  console.log('Select',e.currentTarget.id);
      //  console.log("Full analysis");
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/ai_codes/full_analyze.py";
    } else if (e.currentTarget.id == "hybrid") {
      // console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/emergency_code/analyze_last_correct.py";
    } else if (e.currentTarget.id == "classical") {
      //  console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/classical_code/full_analyze.py";
    } else if (e.currentTarget.id == "temp1") {
      // console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/negpeak/analyze_last_correct.py";
    } else if (e.id == "temp2") {

      //  console.log('Select',e.currentTarget.id);
      window.analyzeLink =
        "/home/ubuntu/analysis_codes/pvc_model/full_analyze.py";
    } else if (e.currentTarget.id == "inversion") {
      //  console.log('Select',e.currentTarget.id);
      window.analyzeLink = "/home/ubuntu/web_analysis/single_invert.py";
      console.log("inversion");
    }
    //console.log('window.analyzeLink',window.analyzeLink);
    //console.log('uid',userid);
    //console.log('uid',databaseid);
    if (window.analyzeLink != "") {
      var userid = this.state.userid;
      var databaseid = this.state.clickdata;
      e.currentTarget.innerHTML = "Analyzing...";
      //const userid = this.props.match.params.userid;
      this.handleCloseModalNewComplete();
      this.handleOpenModalSpinnerNew();
      axios
        .get(
          "https://web.mybiocalculus.com/html/welcome/analyzeAll/?userid=" +
            userid +
            "&&type=" +
            window.analyzeLink
        )
        .then((res) => {
          console.log(
            "https://web.mybiocalculus.com/html/welcome/analyzeAll/?userid=" +
              userid +
              "&&type=" +
              window.analyzeLink
          );
          console.log(res);
          //window.location.href = '/';
          this.handleCloseModalSpinnerNewComplete();
        })
        .catch((err) => {
          console.log(err);
          this.handleCloseModalSpinnerNewComplete();
        });
    }
  }
  _onSelect(e) {
    if (e.value == "RPeaks") {
      console.log("Select", e.value);
      window.analyzeLink =
        "/home/biocalculusssh/web_analysis/rpeaks_codes/full_analyze.py";
    } else if (e.value == "Classic") {
      console.log("Select", e.value);
      window.analyzeLink =
        "/home/biocalculusssh/web_analysis/classic_peak/full_analyze.py";
    } else if (e.value == "Classical") {
      console.log("Select", e.value);
      window.analyzeLink =
        "/home/biocalculusssh/web_analysis/classical_code/full_analyze.py";
    } else if (e.value == "Classic Negative") {
      console.log("Select", e.value);
      window.analyzeLink =
        "/home/biocalculusssh/web_analysis/classic_negpeak/full_analyze.py";
    } else if (e.value == "AI") {
      console.log("Select", e.value);
      window.analyzeLink =
        "/home/biocalculusssh/web_analysis/ai_codes/full_analyze.py";
    } else if (e.value == "AIcodes") {
      console.log("Select", e.value);
      window.analyzeLink =
        "/home/biocalculusssh/web_analysis/saroja_aicodes/full_analyze.py";
    } else if (e.value == "inversion") {
      //  console.log('Select',e.currentTarget.id);
      window.analyzeLink = "/home/ubuntu/web_analysis/invert_data.py";
    }
  }

  render() {
    const imageList = [
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
      {
        src: "../../dist/img/AI.png",
        width: 152,
        height: 102,
      },
    ];
    const customStyles = {
      content: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)", // Ensures perfect centering
        background: "transparent",
        border: "none", // Removes borders
        padding: 0, // Ensures no extra space around content
        display: "flex", // Aligns spinner properly
        justifyContent: "center", // Centers spinner horizontally
        alignItems: "center", // Centers spinner vertically
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adds a semi-transparent overlay
      },
    };
    const customStylesNew = {
      content: {
        top: "38%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        border: "1px solid rgb(204, 204, 204)",
        background: "rgb(255, 255, 255)",
        overflow: "auto",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "45%",
        width: "35%",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    const options = [
      "RPeaks",
      "Classic",
      "Classical",
      "Classic Negative",
      "AI",
      "AIcodes",
      "invert",
    ];
    const text='Analyzing.......'
    const defaultOption = options[0];
    // console.log(this.state.showModalComplete)
    return (
      <React.Fragment>
        <ReactModal
          isOpen={this.state.showModalSpinnerComplete}
          style={customStyles}
        >
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          <div className="bounce-container">
      {text.split('').map((char, index) => (
        <span
          key={index}
          className="bounce-letter"
          style={{ animationDelay: `${index * 0.1}s` }}
        >
          {char}
        </span>
      ))}
    </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showModalComplete}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <React.Fragment>
            <div className="blog-pop-bg"></div>
            <div className="blog-pop-box">
              <button
                className="pull-right closeBtn"
                onClick={this.handleCloseModalComplete}
              >
                &#10006;
              </button>
              <div className="fea pull-left" style={{ marginRight: "50px" }}>
                {this.state.startProp == undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.openAnalyzemodal.bind(this)}
                      src="../../dist/img/blog-pop1.png"
                      alt=""
                    />
                  </button>
                )}
                {this.state.startProp != undefined && (
                  <button style={{ border: "none" }}>
                    <img
                      onClick={this.openAnalyzemodal.bind(this)}
                      src="../../../../../dist/img/blog-pop1.png"
                      alt=""
                    />
                  </button>
                )}
                <p style={{ marginLeft: "30%", fontSize: "25px" }}>Analyze</p>
              </div>
              y
              <br />
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.showModalNewComplete}
          style={customStylesNew}
          contentLabel="Analyze Modal"
        >
          <React.Fragment>
            <div className="blog-pop-box">
              <button
                className="pull-right closeBtn"
                onClick={this.handleCloseModalNewComplete}
              >
                &#10006;
              </button>

              <div className="container col-md-12">
                <div className="row" style={{ marginTop: "15%" }}>
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="ai"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/AI.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="classical"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/CLASSICAL.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="hybrid"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/HYBRID.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="temp1"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/TEMPLATE1.png"
                        alt=""
                      />
                    </button>
                  </div>

                  <div className="col-md-4">
                    <button style={{ border: "none" }}  onClick={() => this.setState({ showInputPopup: true })}>
                      <img
                        id="temp2"
                        // onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/TEMPLATE2.png"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button style={{ border: "none" }}>
                      <img
                        id="inversion"
                        onClick={this.analyzeDataset.bind(this)}
                        src="../../dist/img/inversion.png"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </ReactModal>
        <ReactModal
          isOpen={this.state.showInputPopup}
          style={customStylesNew}
        >
    <div style={{ display: "block" }}>
  <label style={{ color: "black", padding: "5px" }}>
    Enter the point to increase Amp?
  </label>
  <input
    style={{
      color: "black",
      padding: "5px",
      borderRadius: "4px",
      border: "1px solid #ccc",
      marginTop: "10px",
      width: "100%",
      fontSize: "16px",
    }}
    type="text"
    value={this.state.inputValue}
    onChange={this.handleInputChange.bind(this)}
  />
</div>



<div
  style={{
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  }}
>
  <button
    style={{
      padding: "10px 20px",
      backgroundColor: "#4CAF50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      marginRight: "10px",
    }}
    onClick={this.handleInputPopupOk.bind(this)}
  >
    OK
  </button>
  <button
    className="primary"
    style={{
      padding: "10px 20px",
      backgroundColor: "#f44336",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    }}
    onClick={this.handleInputPopupClose.bind(this)}
  >
    Cancel
  </button>
</div>


        </ReactModal>
      </React.Fragment>
    );
  }
}

export default AddModalComplete;
